import React from "react";
import { Box, Container, Typography } from "@mui/material";
import Dashboard from "../../images/ChatDashboard.svg";
import Bullet from "../../images/ListStyle.svg";
import "../../styles/LeadGeneration.scss";

const LeadGeneration = () => {
  return (
    <>
      <Container>
        <Box className="leadHeader">
          <Typography variant="h2">
            Automated Customer Support and an Amazing Lead-Generation Engine
          </Typography>
        </Box>
      </Container>
      <Box className="leadWrapper">
        <Container>
          <Box className="leadBox">
            <Box className="leadContent">
              <Typography variantMapping="p">
                <ul>
                  <Box className="leadBullets">
                    <img src={Bullet} alt="" />
                    <li>Live Chat</li>
                  </Box>
                  <Box className="leadBullets">
                    <img src={Bullet} alt="" />
                    <li>Simplified Chatbot Configuration Process</li>
                  </Box>
                  <Box className="leadBullets">
                    <img src={Bullet} alt="" />
                    <li>Collaborative Workspace and Tools</li>
                  </Box>
                  <Box className="leadBullets">
                    <img src={Bullet} alt="" />
                    <li>Smart Dashboard</li>
                  </Box>
                  <Box className="leadBullets">
                    <img src={Bullet} alt="" />
                    <li>Shared Inbox and Team Chats</li>
                  </Box>
                </ul>
              </Typography>
            </Box>
            <Box className="leadImage">
              <img src={Dashboard} alt="" />
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default LeadGeneration;
