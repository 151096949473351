import React from "react";
import { Container, Box, Typography } from "@mui/material";
import ReadyImage from "../../images/Ready.svg";
import "../../styles/Ready.scss";
import Customizable from "../home/Customizable";

const Ready = () => {
  return (
    <>
      <Container>
        <Box className="readyWrapper">
          <img src={ReadyImage} alt="" />
          <Box className="readyContent">
            <Typography variant="h2">Ready To Get Started?</Typography>
            <Typography variantMapping="p">
              Build your customized chat widget on a cost-effective budget and
              scale as you grow. With InvoCom you can opt for both AI Chatbot
              and live chat agents.
            </Typography>
          </Box>
        </Box>
      </Container>
      <Box className="featureDemoForm">
        <Customizable
          indexprop={2}
          title="Book a free demo with the simplest step"
          textColor="#611F69"
          pointsColor="#232323"
        />
      </Box>
    </>
  );
};

export default Ready;
