import React from "react";
import Layout from "../components/base-structure/Layout";
import "../styles/About.scss";
import Seo from "../components/common/SEO";
import HeroAbout from "../components/about/HeroAbout";
import Technology from "../components/about/Technology";
import Middle from "../components/about/Middle";
// Images
import Aims from "../images/Aims.svg";
import Tool from "../images/Tool.svg";
import Reach from "../images/Reach.svg";
import Ready from "../components/about/Ready";
import LeadGeneration from "../components/about/LeadGeneration";

const content = [
  {
    id: 1,
    metaTitle: "About us - Invocom",
    metaDescription:
      "Create a seamless customer experience with Invocom and watch your business elevate to new heights. Automated AI bots offer real-time support and engagement. ",
  },
];

export default function about() {
  return (
    <Layout>
      {content.map((contents) => (
        <div key={contents.id}>
          <Seo
            title={contents.metaTitle}
            description={contents.metaDescription}
            tag="index"
            pageUrl="about-us/"
          />
        </div>
      ))}
      <HeroAbout />
      <Technology />
      <Middle
        image={Aims}
        title="Aims of Invocom"
        description="The main objective of InvoCom is to facilitate accelerated progress and development that could boost your business in a very short span. An integrated, responsive, and participatory approach to create an efficient and optimum communication network. InvoCom is a compact solution allowing you to build a long-lasting customer satisfaction experience with instant knowledge base messaging while reducing customer queries with an advanced form of problem settlement tactics."
      />
      <Middle
        image={Tool}
        title="An all-in-one customer service tool"
        description="With the rise of online businesses, maintaining strong customer relationships has become more important than ever. At InvoCom, we want to help businesses grow by building meaningful relationships with their customers. Pledge to offer an all-in-one customer communication platform to drive higher conversion rates and growth through better relations. Build, test, and optimize - Our technology empowers you to deploy perfect AI chatbots with multiple integrations and real-time support."
        flexDirection="row-reverse"
      />
      <Middle
        image={Reach}
        title="Extend your reach"
        description={
          <p>
            Invocom has opened new channels for B2C communication. Businesses
            can engage customers with a 24/7 conversation platform. Our instant
            messaging service makes each connection simple, friendly, practical,
            and convenient.
            <ul>
              <span>What defines InvoCom:</span>
              <li>Working for a distributed connection yet engaging</li>
              <li>Creating a customer impact</li>
              <li>Passionate and always learning</li>
              <li>Growth and evaluation</li>
            </ul>
          </p>
        }
      />
      <LeadGeneration />
      <Ready />
    </Layout>
  );
}
