import React from "react";
import { Box, Container, Typography } from "@mui/material";
import "../../styles/MiddleWrapper.scss";

const Middle = (props) => {
  return (
    <Container>
      <Box
        className="middleWrapper"
        sx={{
          flexDirection: `${props.flexDirection}`,
          "@media screen and (max-width:992px)": {
            flexDirection: "column",
          },
        }}
      >
        <Box className="middleImage">
          <img src={props.image} alt="" />
        </Box>
        <Box className="middleContent">
          <Typography variant="h2">{props.title}</Typography>
          <Typography variantMapping="p">{props.description}</Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default Middle;
