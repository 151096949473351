import React from "react";
import { Container, Box, Typography, Divider } from "@mui/material";
import TechImage from "../../images/technoImage.svg";
import "../../styles/Technology.scss";

const Technology = () => {
  return (
    <>
      <Container>
        <Box className="techWrapper">
          <Typography variantMapping="p">
            <span style={{ color: "#FCB92B" }}>
              InvoCom is constructed on a seamless AI/ML technology which
              ensures:
            </span>
            <ul>
              <li>Real-time support</li>
              <li>Customer engagement</li>
              <li>Consistent communication ties</li>
            </ul>
          </Typography>
          <img src={TechImage} alt="" />
        </Box>
        <Box className="packageWrapper">
          <Typography variant="h2">
            A complete customer service representative package that can grow
            your business
          </Typography>
          <Divider
            sx={{
              background: "#FCB92B",
              maxWidth: "240px",
              minHeight: "3px",
              border: "none",
              margin: "40px auto 70px",
              "@media screen and (max-width:768px)": {
                margin: "30px auto",
              },
            }}
          />
        </Box>
      </Container>
    </>
  );
};

export default Technology;
