import React from "react";
import { Box, Typography, Button } from "@mui/material";
import "../../styles/HeroAbout.scss";
import { Link } from "gatsby";

const HeroAbout = () => {
  return (
    <>
      <Box className="heroAbout">
        <Typography variant="h1">
          If you’re looking for an AI chatbot for customer support then you’ve
          come to the right place.
        </Typography>
        <Box className="trial_Btn_wrapper">
          <Link to="https://app.invocom.io/signup/" target="_blank">
            <Button className="trial_btn" id="trial_btn">
              Free 14-Days Trial
            </Button>
          </Link>
        </Box>
      </Box>
    </>
  );
};

export default HeroAbout;
